// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pK";
export var articleText = "n_pL d_dr d_cp";
export var header = "n_pM d_W d_s";
export var headerImageWrapper = "n_pN d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "n_pP d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "n_pQ d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "n_pR d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "n_mC d_s";
export var dateTag = "n_pS d_cb d_cn d_dm d_s";
export var icon1 = "n_pT d_cL";
export var icon2 = "n_pV d_cL d_cF";
export var tagBtn = "n_pW d_bY d_cz d_dl d_dx";
export var headerText = "n_pX d_s d_cb d_cp d_bS";
export var center = "n_pY d_ds";
export var videoIframeStyle = "n_pZ d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "n_p0 d_cp d_W";
export var articleImageWrapperIcon = "n_p1 d_cp d_W";
export var articleRow = "n_p2 d_bz d_bb";
export var quoteWrapper = "n_gN d_by d_bL d_cp";
export var quoteBar = "n_p3 d_D";
export var quoteText = "n_p4";
export var authorBox = "n_p5 d_s";
export var authorRow = "n_p6 d_bz d_bS d_cv";
export var separator = "n_p7 d_s";
export var line = "n_fg d_s d_fg d_cV d_cv";
export var authorImage = "n_lr d_b4 d_W d_s d_cq";
export var authorText = "n_p8 d_cd d_cq";
export var masonryImageWrapper = "n_p9";
export var bottomSeparator = "n_qb d_s d_cv";
export var linksWrapper = "n_qc d_cd d_dr";
export var comments = "n_qd d_bz d_cv";
export var sharing = "n_qf d_bw d_bz d_bF";
export var shareText = "n_qg d_s d_ds";
export var icon = "n_qh";
export var text = "n_qj";
export var SubtitleSmall = "n_qk";
export var SubtitleNormal = "n_ql";
export var SubtitleLarge = "n_qm";