// extracted by mini-css-extract-plugin
export var carouselContainer = "y_sN d_s d_D d_bb d_W";
export var carouselContainerCards = "y_sP y_sN d_s d_D d_bb d_W";
export var carouselContainerSides = "y_sQ d_s d_D d_W";
export var prevCarouselItem = "y_sR d_s d_D d_X d_g";
export var prevCarouselItemL = "y_sS y_sR d_s d_D d_X d_g";
export var moveInFromLeft = "y_sT";
export var prevCarouselItemR = "y_sV y_sR d_s d_D d_X d_g";
export var moveInFromRight = "y_sW";
export var selectedCarouselItem = "y_sX d_s d_D d_W d_bb";
export var selectedCarouselItemL = "y_sY y_sX d_s d_D d_W d_bb";
export var selectedCarouselItemR = "y_sZ y_sX d_s d_D d_W d_bb";
export var nextCarouselItem = "y_s0 d_s d_D d_X d_g";
export var nextCarouselItemL = "y_s1 y_s0 d_s d_D d_X d_g";
export var nextCarouselItemR = "y_s2 y_s0 d_s d_D d_X d_g";
export var arrowContainer = "y_s3 d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainer = "y_s4 y_s3 d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainerHidden = "y_s5 y_s4 y_s3 d_D d_X d_dx d_bh d_1 d_bY d_g";
export var nextArrowContainer = "y_s6 y_s3 d_D d_X d_dx d_bh d_1 d_bY";
export var carouselContainerProducts = "y_s7";
export var nextArrowContainerHidden = "y_s8 y_s6 y_s3 d_D d_X d_dx d_bh d_1 d_bY d_g";
export var arrow = "y_kK d_X";
export var prevArrow = "y_s9 y_kK d_X";
export var nextArrow = "y_tb y_kK d_X";
export var carouselIndicatorContainer = "y_tc d_s d_ds d_bh d_bw d_bz d_bL d_bF d_X";
export var btnsContainer = "y_td d_s d_bw d_bB";
export var carouselText = "y_tf d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var carouselTextInactive = "y_tg y_tf d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var indicator = "y_th d_b4";
export var carouselIndicator = "y_tj y_th d_b4";
export var carouselIndicatorSelected = "y_tk y_th d_b4";
export var arrowsContainerTopRight = "y_tl d_X d_bh d_by";
export var arrowsContainerBottomLeft = "y_tm d_X d_bh d_by";
export var arrowsContainerSides = "y_tn d_X d_bh d_by";
export var smallArrowsBase = "y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainer = "y_tq y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrev = "y_tr y_tq y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerSmall = "y_ts y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrevSmall = "y_tt y_ts y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var icon = "y_qh";
export var iconSmall = "y_tv";
export var multipleWrapper = "y_tw d_by d_bB d_bb";
export var multipleImage = "y_tx d_by";
export var sidesPrevContainer = "y_ty y_tq y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";
export var sidesNextContainer = "y_tz y_tq y_tp d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";