// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_sw";
export var lbContainerInner = "w_sx";
export var movingForwards = "w_sy";
export var movingForwardsOther = "w_sz";
export var movingBackwards = "w_sB";
export var movingBackwardsOther = "w_sC";
export var lbImage = "w_sD";
export var lbOtherImage = "w_sF";
export var prevButton = "w_sG";
export var nextButton = "w_sH";
export var closing = "w_sJ";
export var appear = "w_sK";