// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qn d_gs d_cp";
export var heroHeaderCenter = "p_gt d_gt d_cp d_ds";
export var heroHeaderRight = "p_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "p_qp d_gp d_cs";
export var heroParagraphCenter = "p_gq d_gq d_cs d_ds";
export var heroParagraphRight = "p_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "p_qq d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "p_qr d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "p_qs d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "p_qt d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "p_qv d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "p_qw v_qw";
export var heroExceptionNormal = "p_qx v_qx";
export var heroExceptionLarge = "p_qy v_qy";
export var Title1Small = "p_qz v_qz v_rn v_rp";
export var Title1Normal = "p_qB v_qB v_rn v_rq";
export var Title1Large = "p_qC v_qC v_rn v_rr";
export var BodySmall = "p_qD v_qD v_rn v_rH";
export var BodyNormal = "p_qF v_qF v_rn v_rJ";
export var BodyLarge = "p_qG v_qG v_rn v_rK";